import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Animated from '../components/animated'

const NotFoundPage = () => (
    <Layout>
        <Animated animation="fade-down" delay={1000}>
            <h1 className="text-lg md:text-xl text-white text-shadow">404</h1>
        </Animated>
        <Animated animation="fade-down" delay={1250}>
            <div className="text-sm md:text-base text-white-60 leading-normal mt-8 mb-12 text-shadow">
                <p>
                    Whatever you were looking for definitely doesn't exist, there's only{' '}
                    <Link
                        to="/"
                        className="text-white no-underline border-b-4 border-transparent hover:border-purple"
                    >
                        one page
                    </Link>{' '}
                    for our site.
                </p>
            </div>
        </Animated>
    </Layout>
)

export default NotFoundPage
